import React from "react";

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";

const Demo4Page = () => {
	return (
		<LayoutWidget>
			<Seo
				seo={{
					metaTitle: "Order page",
					metaDescription: "It looks like you got to the order page",
				}}
			/>

			<div className="mx-auto mb-12 items-center p-4 lg:flex lg:max-w-[1440px] lg:space-x-12 lg:px-8 xl:px-10">
				<rtr-order orderHash=" "></rtr-order>
			</div>
		</LayoutWidget>
	);
};

export default Demo4Page;
